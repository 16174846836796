.noteContainer {
  display: block;
  background-color: var(--extraStatus_constant_yellow2_background);
  grid-area: subheader;
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 7px;
  .button {
    height: auto;
    padding: 0;
    padding-inline: 8px;
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    text-align: start;
    .title {
      display: block;
      margin: 0;
      padding-bottom: 4px;
      color: var(--gl_background_accent);
      font: var(--font-13-b);
      text-transform: capitalize;
    }
    font: var(--font-12-r);
    color: var(--gl_text_content_primary);
    padding-inline: 8px;
    overflow: hidden;
    border-left: 2px solid var(--gl_background_accent);
    display: -webkit-box;
    overflow-wrap: break-word;
    white-space: normal;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.emptyNote {
  grid-area: subheader;
}
