@import "styles/mixins";

.container {
  display: grid;
  height: 100dvh;
  grid-template-columns:
    repeat(3, minmax(0, min-content)) minmax(0, max-content)
    minmax(0, 1fr);
  grid-template-rows: minmax(0, max-content) minmax(0, max-content) 1fr minmax(0, max-content);
  grid-template-areas:
    "sidebar_header sidebar_header layout_header layout_header layout_header"
    "sidebar subheader subheader subheader subheader"
    "sidebar content_col1 content_col2 content_col3 content_col4"
    "sidebar footer_col1 footer_col2 footer_col3 footer_col4";
  -webkit-print-color-adjust: exact;
  overflow: hidden;
  &.modal {
    --z-index-multi: 0;
  }

  @media print {
    grid-template-columns: 0 minmax(0, min-content) minmax(0, 1fr);
  }
}

.version {
  position: absolute;
  right: 5px;
  bottom: 2px;
  color: var(--cadet-blue);
  font-size: 0.7em;
}

.main {
  height: 100%;
  overflow: auto;
  background-color: var(--catskill-white);
  box-sizing: border-box;
  grid-area: content_col1/footer_col1/content_col4/footer_col4;
  display: grid;
  grid-gap: var(--header-spacing);
  grid-template-areas: "booking registration";
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @include tablet {
    grid-template-columns: 1fr;
    grid-template-areas: none;

    > * {
      box-shadow: 0px 2px 3px rgba(180, 199, 236, 0.35);
      border-radius: 3px;
    }
  }
}

.dialogs {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;

  &.booking {
    grid-template-columns: 0fr auto 710px;
    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  @include tablet {
    grid-template-columns: 1fr;
  }
}

.settings {
  grid-template-columns: 100%;
  overflow-x: hidden;
}

.create-booking-new {
  grid-template-columns: 364px 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "stepper form"
    "footer footer";
  background-color: var(--gl_background_1);
}
